import React from "react";

const Teplomer = ({ fillLevel = 50, width = 40, height = 10 }: { fillLevel?: number, width?: number, height?: number }) => {
  //const width = 40;
  //const height = 10;
  const borderRadius = 5;
  const fillWidth = (fillLevel / 100) * width;

  // Barevný přechod podle naplněnosti
  const getFillColor = () => {
    if (fillLevel < 50) return "#4caf50"; // zelená
    if (fillLevel < 80) return "#FFB74D"; // oranžová
    return "#f44336"; // červená
  };

  return (
    <svg width={width + 10} height={height + 10}>
      {/* Rámeček */}
      <rect
        x={5}
        y={5}
        width={width}
        height={height}
        rx={borderRadius}
        ry={borderRadius}
        fill="#e0e0e0"
        stroke="#ccc"
        strokeWidth={2}
      />
      {/* Naplnění */}
      <rect
        x={5}
        y={5}
        width={fillWidth}
        height={height}
        rx={borderRadius}
        ry={borderRadius}
        fill={getFillColor()}
      />
      {/* Procenta */}
      { width >= 40 && <text
        x={(width + 10) / 2}
        y={(height + 10) / 2 + 4}
        fontSize="11"
        fontWeight="bold"
        textAnchor="middle"
        fill={fillLevel > 80 ? '#fff' : '#222'}
      >
        {fillLevel}%
      </text>
        }
    </svg>
  );
};

export default Teplomer;
