import { Typography, Grid } from '@material-ui/core'; // , List, ListItem, ListItemIcon, ListItemText
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
// import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
// import { Link } from "react-router-dom";

import Page from '../Components/Layout/Page';

const WebinarPageMessages = defineMessages({
    pagesMediaSectionPartnersTitle: {
        id: 'PAGES_WEBINAR_TITLE',
        defaultMessage: 'Administrace Kam s ním?',
    },
});

const WebinarPage: React.FC = () => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Page currentPage="webinar">
            <div style={{padding: '5px', textAlign: 'center'}}>
            <Typography className={ classes.title } variant="h4" component="h1">
            { intl.formatMessage(WebinarPageMessages.pagesMediaSectionPartnersTitle) }
            </Typography>
            <strong>
                Vstup do administrace: <a href="https://admin.kamsnim.cz" target='_blank'>https://admin.kamsnim.cz</a>
            </strong>
            <br /><br /><br />
            <Typography className={ classes.title } variant="h5" component="h3">
            Záznam webináře, ve kterém jsme představili novou administraci míst pro obce.
            </Typography>
            <iframe
        width="100%"
        height="460"
        src="https://www.youtube.com/embed/FDMFs6MHEAg"
        title="Webinář Kam s ním?"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      ></iframe>
        </div>
        </Page>
    );
};

export default WebinarPage;

const useStyles = makeStyles((theme: Theme) => createStyles({
    title: {
        marginBottom: theme.spacing(3),
    },
    root: {

    },
    img: {
        margin: theme.spacing(1),
    },
}));
